import { useMemo } from "react";
import Head from "next/head";
import styles from './lazyImage.module.css';

// <svg width="700" height="475" xmlns="http://www.w3.org/2000/svg" version="1.1"/>
type Props = {
  priority?: boolean, layout?: string, alt: string, src?: string, sizes?: string, srcset?: string, sources?: [], width?: number, height?: number, objectFit?:  "fill" | "contain" | "cover" | "none" | "scale-down", objectPosition:  "sync" | "async" | "auto" | undefined, noLazy?: boolean, radius?: boolean
}

type newProps = {
  wrapperProps: {
    style: {
      objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down",
      objectPosition?: string
    }
  },
  spacerProps: {
    style: {
      paddingTop?: string,
      height?: number,
      width?: number,
    }
  },
  spacerImg: {
    style: object
  },
  imageProps: {
    style: {
      objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down",
      objectPosition?: "sync" | "async" | "auto",
    },
    src: string,
    sizes: string,
    srcSet: string,
    loading?: "lazy" | "eager",
    alt: string,
    fetchPriority?: "auto" | "high" | "low",
    decoding?: "sync" | "async" | "auto",
  },
  imageSources: any
}

const LazyImage = (props) => {
  const { wrapperProps, spacerProps, spacerImg, imageProps, imageSources } = useMemo(() => {
    let newProps: newProps = {
      wrapperProps: {
        style: {},
      },
      spacerProps: {
        style: {},
      },
      spacerImg: {
        style: {},
      },
      imageProps: {
        style: {},
        src: '',
        sizes: '',
        srcSet: '',
        alt: '',
      },
      imageSources: null,
    };

    let { priority, layout, alt, src, sizes, srcset, sources, width, height, objectFit, objectPosition, noLazy, radius = false }: Props = props;
    if (src) newProps.imageProps.src = src;
    if (sizes) newProps.imageProps.sizes = sizes;
    if (srcset) newProps.imageProps.srcSet = srcset;
    if (alt) newProps.imageProps.alt = alt;
    if (priority) {
      newProps.imageProps.decoding = "sync";
      newProps.imageProps.fetchPriority = "high";
      newProps.imageProps.loading = "eager";
    } else if (!noLazy) {
      newProps.imageProps.loading = "lazy";
      newProps.imageProps.decoding = "async";
    }

    if (sources) newProps.imageSources = sources;

    if (width && height) {
      if (layout === "responsive") {
        // newProps.spacerProps.style.paddingTop = `${width * (height / width)}px`
        newProps.spacerProps.style.paddingTop = `${100 * (height / width)}%`;
      }


      if (layout === "fixed") {
        newProps.spacerProps.style.height = height;
        newProps.spacerProps.style.width = width;
      }
    }

    if (layout === "fill") {
      if (objectFit) {
        newProps.imageProps.style.objectFit = objectFit;
        newProps.wrapperProps.style.objectFit = objectFit;
      }

      if (radius) {
        newProps.wrapperProps.style['borderRadius'] = '5px';
      }

      if (objectPosition) {
        newProps.wrapperProps.style.objectPosition = objectPosition;
        newProps.imageProps.style.objectPosition = objectPosition;
      }
    }

    return newProps;
  }, [props]);
  switch (props.layout) {
    case "fixed":
      return (
        <div className={`${styles.RegularImage} ${styles.Fixed}`} {...wrapperProps}>
          <picture>
            {imageSources && imageSources.map((src, i) => <source key={i} {...src} />)}
            <img {...imageProps} />
          </picture>
        </div>
      );

    case "responsive":
      return (
        <div className={`${styles.RegularImage} ${styles.Resonsive}`} {...wrapperProps}>
          <div {...spacerProps} />
          <picture>
            {imageSources && imageSources.map((src, i) => <source key={i} {...src} />)}
            <img {...imageProps} />
          </picture>
        </div>
      );

    case "fill":
      return (
        <div className={`${styles.RegularImage} ${styles.Fill}`} {...wrapperProps}>
          <picture>
            {imageSources && imageSources.map((src, i) => <source key={i} {...src} sizes={props.sizes} />)}
            <img {...imageProps} />
          </picture>
        </div>
      );

    case "cover":
      return (
        <div className={`${styles.RegularImage} ${styles.Cover}`} {...wrapperProps}>
          {props.priority && (
            <Head>
              <link rel="preload" href={imageProps.src} as="image" />
            </Head>
          )}
          <picture>
            {imageSources && imageSources.map((src, i) => <source key={i} {...src} />)}
            <img {...imageProps} />
          </picture>
        </div>
      );

    default:
      return (
        <div className={`${styles.RegularImage} ${styles.Intrinsic}`} {...wrapperProps}>
          <div {...spacerProps}>
            <img {...spacerImg} loading="lazy" />
          </div>
          <picture>
            {imageSources && imageSources.map((src, i) => <source key={i} {...src} />)}
            <img {...imageProps} />
          </picture>
        </div>
      );
  }
};

export default LazyImage;
